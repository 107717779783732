import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn: import.meta.env.SENTRY_DSN,
  release: import.meta.env.SENTRY_RELEASE,
  environment: import.meta.env.SENTRY_ENVIRONMENT,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.replayIntegration({
      // Additional SDK configuration goes in here, for example:
      maskAllText: false,
      blockAllMedia: true,
    }),
  ],
});
Sentry.setTag('stack', 'frontend');

window.Sentry = Sentry;
